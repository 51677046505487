<template>
  <div class="list-item" v-bind:class="`${selected ? 'active': 'inactive'}`" v-on:click="setSelected">
    <div class="list-item__header">
      <h4>{{retailer.web_namn}}</h4>
      <span class="list-item__exit" v-show="selected"><i class="far fa-times"></i></span>
    </div>
    <div class="list-item__body">
                    <span class="list-item__online" v-if="retailer['web-shop'] !== ''">
                        <i class="fas fa-globe-europe"></i> {{getTranslation('onlineStore')}}
                    </span>

      <div class="list-item__address">
        <span>{{retailer.adress_web}}</span>
        <span>{{retailer.postnummer_web}} {{retailer.ort_web}}</span>
      </div>

      <div class="list-item__expandable-content">
        <div class="list-item__directions">
          <a v-bind:href="getDirectionUrl(retailer)" target="_blank">{{getTranslation('directions')}}</a>
          <i class="far fa-external-link"></i>
        </div>

        <a v-bind:href="retailer['web-shop']" target="_blank" v-if="retailer['web-shop'] !== ''"
           class="btn btn--retailers">{{getTranslation('goToOnline')}}</a>

        <div class="list-item__contacts">
          <div class="list-item__email">
            <span>{{getTranslation('email')}}</span>
            <span>{{retailer.web_tel}}</span>
          </div>
          <div class="list-item__phone">
            <span>{{getTranslation('phone')}}</span>
            <span>{{retailer.web_tel}}</span>
          </div>
        </div>

        <div class="list-item__services">
          <span>{{getTranslation('helpYouWith')}}</span>

          <div class="list-item__service" v-for="(term, termIndex) in terms" v-bind:key="termIndex">
            <div class="list-item__service-name">
              {{term.label}}
            </div>
            <div class="list-item__service-icons">
              <span><i class="far fa-check"></i></span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { language } from '../mixins/language.js'

export default {
  name: 'list-item',
  mixins: [language],

  props: {
    retailer: {
      type: Object,
      default: () => {}
    },
    brands: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    terms () {
      let values = []
      this.brands.forEach(brand => {
        brand.terms.forEach(termSlug => {
          if (this.retailer[termSlug] === 'Y') {
            values.push({
              termSlug: termSlug,
              label: brand.term_labels[termSlug]
            })
          }
        })
      })
      return values
    }
  },
  methods: {
    setSelected () {
      this.$emit('click', { retailer: this.retailer, selected: this.selected })
    },
    getDirectionUrl (retailer) {
      // The extra slash is not a mistake, remove it and google will treat the address as start position instead.
      const baseUrl = 'https://www.google.com/maps/dir//'
      return baseUrl + retailer.adress_web.replace(' ', '+') + ',' + retailer.postnummer_web.replace(' ', '+') + ',' + retailer.ort_web.replace(' ', '+')
    }
  }
}
</script>
